/**
 * Badge schema
 */

import rules from './validationRules';
import i18n from '../i18n';
import { datatypes, getBooleanTypeReferences } from './datatypes';
import { getItemStatusTypeReferences } from './itemStatus';
import { roleAdmin, roleSuperAdmin } from './accessRolesModel';

const badgeFieldId = {
  name: 'id',
  type: datatypes.string,
  description: 'Unique id of the badge',
  label: '',
  allowedRoles: {
    read: [],
    write: [],
  },
};

const badgeFieldMacAddress = {
  name: 'macAddress',
  type: datatypes.string,
  description: 'macAddress of the badge',
  minLength: 1,
  maxLength: 64,
  label: i18n.tc('badge.fields.macAddress', 1),
  rules: [rules.required],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
};

const badgeFieldUuid = {
  name: 'uuid',
  type: datatypes.string,
  description: 'UUID of the badge',
  minLength: 1,
  maxLength: 64,
  label: i18n.tc('badge.fields.uuid', 1),
  rules: [rules.required],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },

};

const badgeFieldRfidTagId = {
  name: 'rfidTagId',
  type: datatypes.string,
  description: 'RFID tag ID of the badge.',
  minLength: 0,
  maxLength: 128,
  label: i18n.tc('badge.fields.rfidTagId', 1),
  rules: [rules.required],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const badgeFieldPrivateParams = {
  name: 'privateParams',
  type: datatypes.json,
  description: 'Private Parameters associated to the Badge.',
  label: i18n.tc('badge.fields.privateParams', 1),
  minLength: 0,
  maxLength: 1024,
  rules: [rules.isJson],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [roleSuperAdmin],
  },
};

const badgeFieldBadgeIsActive = {
  name: 'badgeIsActive',
  type: datatypes.boolean,
  description: 'The badge is currently active.',
  label: i18n.tc('badge.fields.badgeIsActive', 1),
  references: getBooleanTypeReferences(),
  rules: [],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [roleSuperAdmin],
  },
};

const badgeFieldItemStatus = {
  name: 'itemStatus',
  type: datatypes.integer,
  description: 'Etat de l élément.',
  label: i18n.tc('badge.fields.itemStatus', 1),
  references: getItemStatusTypeReferences(),
  rules: [],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
};

const badge = {
  fields: [
    badgeFieldId,
    badgeFieldMacAddress,
    badgeFieldUuid,
    badgeFieldRfidTagId,
    badgeFieldPrivateParams,
    badgeFieldBadgeIsActive,
    badgeFieldItemStatus,
  ],
  crudAllowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    readInactive: [roleSuperAdmin],
    create: [roleSuperAdmin],
    update: [roleSuperAdmin],
    delete: [],
  },
};

export default badge;
export {
  badge,
  badgeFieldId,
  badgeFieldMacAddress,
  badgeFieldUuid,
  badgeFieldRfidTagId,
  badgeFieldPrivateParams,
  badgeFieldBadgeIsActive,
  badgeFieldItemStatus,
};
